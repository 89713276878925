import PropTypes from "prop-types";
import React from "react";
import UploadImageComponent from "../../../components/upload-image";
import { useUpdateCompany } from "../../../hooks/company.hooks";
import { useUpdateStaffImage } from "../../../hooks/staff.hooks";

const UpdateCompanyUploadImage = ({ type, companyId, values, aspect }) => {
  const uploadFile = useUpdateStaffImage();
  const updateCompany = useUpdateCompany();
  const handleUploadFile = (file) => {
    const formData = new FormData();
    formData.append(`file`, file);
    uploadFile.mutate(formData, {
      onSuccess: (imageURL) => {
        updateCompany.mutate({
          id: companyId,
          params: {
            themeDetails: {
              [type]: imageURL,
            },
          },
        });
      },
    });
  };

  return (
    <div className={`upload-image ${type}`}>
      <UploadImageComponent
        imageURL={values[type]}
        handleSetCroppedImage={handleUploadFile}
        aspect={aspect}
      />
    </div>
  );
};

UpdateCompanyUploadImage.propTypes = {
  type: PropTypes.string,
};

export default UpdateCompanyUploadImage;
