import React from "react";
import UpdateCompanyColorPicker from "./update-company-color-picker";
import AccountItemContainer from "./acccount-item-container";
import AccountItemBody from "./account-item-body";
import AccountItemHeader from "./account-item-header";
import UpdateCompanyUploadImage from "./update-company-upload-image";

const ThemeDetails = ({ theme = {}, companyId = "" }) => {
  return (
    <AccountItemContainer>
      <AccountItemHeader title="Theme Details" />
      <AccountItemBody
        title="Primary Color"
        element={
          <UpdateCompanyColorPicker
            key={theme.primaryColor}
            companyId={companyId}
            type="primaryColor"
            value={theme.primaryColor}
          />
        }
      />
      <AccountItemBody
        title="Secondary Color"
        element={
          <UpdateCompanyColorPicker
            key={theme.secondaryColor}
            companyId={companyId}
            type="secondaryColor"
            value={theme.secondaryColor}
          />
        }
      />
      <AccountItemBody
        title="Icon"
        element={
          <UpdateCompanyUploadImage
            key={theme?.iconUrl}
            companyId={companyId}
            type="iconUrl"
            values={theme}
            aspect={1}
          />
        }
      />
      <AccountItemBody
        title="Logo"
        element={
          <UpdateCompanyUploadImage
            key={theme?.logoURL}
            companyId={companyId}
            type="logoUrl"
            values={theme}
            aspect={1.5}
          />
        }
      />
      <AccountItemBody
        title="Dark Icon"
        element={
          <UpdateCompanyUploadImage
            key={theme?.iconDarkUrl}
            companyId={companyId}
            type="iconDarkUrl"
            values={theme}
            aspect={1}
          />
        }
      />
      <AccountItemBody
        title="Dark Logo"
        element={
          <UpdateCompanyUploadImage
            key={theme?.logoDarkUrl}
            companyId={companyId}
            type="logoDarkUrl"
            values={theme}
            aspect={1.5}
          />
        }
      />
    </AccountItemContainer>
  );
};

export default ThemeDetails;
