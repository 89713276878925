import React, { useMemo } from "react";
import { STAFF_ROLE } from "../../../commons/enum";
import {
  useCompanyConnectiveDetails,
  useCompanyInfo,
} from "../../../hooks/company.hooks";
import CompanyConnective from "./company-connective";
import CompanyDetails from "./company-details";
import ThemeDetails from "./company-theme";

const CompanySetting = () => {
  const { data: companyInfo } = useCompanyInfo();
  const companyConnectiveDetails = useCompanyConnectiveDetails();
  const isBrokerRole = useMemo(() => {
    return companyInfo?.adminDetails?.staffRoles?.some(
      (role) => role.role === STAFF_ROLE.BROKER
    );
  }, [companyInfo?.adminDetails?.staffRoles]);
  return (
    <div className="account-container">
      <CompanyDetails companyInfo={companyInfo} />
      {isBrokerRole && (
        <CompanyConnective
          companyInfo={companyInfo}
          companyConnectiveDetails={companyConnectiveDetails}
        />
      )}
      <ThemeDetails companyId={companyInfo?.id} theme={companyInfo?.theme} />
    </div>
  );
};

export default CompanySetting;
