import { ClickAwayListener, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { useDebounce } from "../../../hooks/common.hooks";
import { useUpdateCompany } from "../../../hooks/company.hooks";

const DEBOUCE_TIME = 1000;

const UpdateCompanyColorPicker = ({ type, companyId, value }) => {
  const updateCompany = useUpdateCompany();
  const [selectedColor, setSelectedColor] = useState(value);
  const [pickerVisible, setPickerVisible] = useState(false);
  const debouncedSearchTerm = useDebounce(selectedColor, DEBOUCE_TIME);
  const handleOpenPicker = () => {
    setPickerVisible(!pickerVisible);
  };
  const handleClickAway = () => {
    setPickerVisible(false);
  };
  const handleColorChange = ({ hex }) => {
    setSelectedColor(hex);
  };

  const handleFieldBlur = () => {
    updateCompany.mutate({
      id: companyId,
      params: {
        themeDetails: {
          [type]: selectedColor,
        },
      },
    });
  };
  const handleInputColor = (e) => {
    setSelectedColor(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (debouncedSearchTerm !== value) {
        updateCompany.mutate({
          id: companyId,
          params: {
            themeDetails: {
              [type]: debouncedSearchTerm,
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="add-staff-color-picker">
        <TextField
          label=""
          id={type}
          name={type}
          value={selectedColor}
          onBlur={handleFieldBlur}
          size="small"
          className={`add-staff__field-input ${type}`}
          onChange={handleInputColor}
        />
        <div>
          <div
            className="add-staff-color-picker__swatch"
            onClick={handleOpenPicker}
          >
            <div
              className="add-staff-color-picker__color"
              style={{ background: `${selectedColor}` }}
            />
          </div>
          {pickerVisible && (
            <div style={{ position: "absolute", zIndex: 1 }}>
              <ChromePicker
                color={selectedColor}
                onChange={handleColorChange}
              />
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

UpdateCompanyColorPicker.propTypes = {};

export default UpdateCompanyColorPicker;
