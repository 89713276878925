import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { STAFF_ROLE } from "../../../commons/enum";
import AccountItemContainer from "./acccount-item-container";
import AccountItemBody from "./account-item-body";
import AccountItemHeader from "./account-item-header";
import EditableTextField from "./editable-text-field";

const CompanyDetails = ({ companyInfo = {} }) => {
  const isBrokerRole = useMemo(() => {
    return companyInfo?.adminDetails?.staffRoles?.some(
      (role) => role.role === STAFF_ROLE.BROKER
    );
  }, [companyInfo?.adminDetails?.staffRoles]);

  return (
    <AccountItemContainer>
      <AccountItemHeader title="Company Details" />
      <AccountItemBody
        title="Name"
        element={<EditableTextField value={companyInfo} name="name" />}
      />

      <AccountItemBody
        title="ACN (Australian Company Number)"
        element={<EditableTextField value={companyInfo} name="companyNumber" />}
      />
      {isBrokerRole ? (
        <AccountItemBody
          title="Australian Credit License Number"
          element={
            <EditableTextField value={companyInfo} name="creditLicenseNumber" />
          }
        />
      ) : (
        <AccountItemBody
          title="Australian Financial Services License Number"
          element={
            <EditableTextField
              value={companyInfo}
              name="financialServicesLicenseNumber"
            />
          }
        />
      )}

      <AccountItemBody
        title="Australian Credit Rep Number"
        element={
          <EditableTextField value={companyInfo} name="creditRepNumber" />
        }
      />

      <AccountItemBody
        title="Customer Suport Email"
        element={
          <EditableTextField value={companyInfo} name="customerSupportEmail" />
        }
      />
      <AccountItemBody
        title="Business Phone Number"
        element={
          <EditableTextField value={companyInfo} name="businessPhoneNumber" />
        }
      />
    </AccountItemContainer>
  );
};

CompanyDetails.propTypes = {
  companyInfo: PropTypes.object,
};

export default CompanyDetails;
