import Edit from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useUpdateCompany } from "../../../hooks/company.hooks";

const EditableTextField = ({ value, name }) => {
  const formRef = useRef();
  const { mutate: updateCompany } = useUpdateCompany();

  const [editFieldState, setEditFieldState] = useState({
    editMode: false,
    mouseOver: false,
    focused: false,
  });

  const handleMouseOver = () => {
    if (!editFieldState.mouseOver && !editFieldState.focused) {
      setEditFieldState({
        ...editFieldState,
        mouseOver: true,
        editMode: true,
      });
    }
  };

  const handleMouseOut = () => {
    if (editFieldState.mouseOver && !editFieldState.focused) {
      setEditFieldState({
        ...editFieldState,
        mouseOver: false,
        editMode: false,
      });
    }
  };

  const handleClick = () => {
    setEditFieldState({
      ...editFieldState,
      editMode: true,
      focused: true,
      mouseOver: false,
    });
  };

  const debouceUpdate = debounce(() => {
    setEditFieldState({ ...editFieldState, editMode: false, focused: false });
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, 500);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        [name]: value[name],
      }}
      validate={(values) => {
        const errors = {};
        if (!values[name]) {
          errors[name] = "Required";
        }
        return errors;
      }}
      onSubmit={(values) => {
        updateCompany({ id: value.id, params: values });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="account-setting__form">
          <TextField
            variant="standard"
            name={name}
            value={values?.[name] || ""}
            margin="normal"
            className="account-setting__field-input"
            onChange={(e) => {
              handleChange(e);
              debouceUpdate();
            }}
            disabled={!editFieldState.editMode}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onClick={handleClick}
            helperText={errors[name] && touched[name] && errors[name]}
            error={errors[name] && touched[name]}
            InputProps={{
              endAdornment: editFieldState.mouseOver ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClick}>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              ) : (
                ""
              ),
            }}
          />
        </form>
      )}
    </Formik>
  );
};

EditableTextField.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
};

export default EditableTextField;
